import './App.css';
import Navigation from './components/navigation/navigation.component';
import Homepage from './pages/homepage/homepage.component';
import PhotoGallery from './pages/photogallery/photogallery.component';
import WebDev from './pages/webdev/webdev.component';
import About from './pages/about/about.component';
import Viewer from './components/viewer/viewer.component';
import Coach from './pages/coach/coach.component';

import { Route, Switch } from 'react-router-dom';

function App() {
 
  return (
    <div className="App">
      {/* <Navigation onHome={false}/> */}
      <Switch>
        <Route exact path='/' component={Homepage} />
        <Route path='/photos' component={PhotoGallery} />
        <Route path='/webdev' component={WebDev} />
        <Route path='/viewer' component={Viewer} />
        <Route path='/coaching' component={Coach} />
        <Route path='/about' component={About} />
      </Switch> 
    </div>
  );
}

export default App;
