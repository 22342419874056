import React from 'react';

import './photogallery.styles.scss';
import imageUrl from '../../assets/iceland_mtn-9518.jpg';

import { Route } from 'react-router-dom';
import GalleryOverview from '../../components/gallery-overview/gallery-overview.component';
import Viewer from '../../components/viewer/viewer.component';

const PhotoGallery = ({match}) => (
      <div className='photogallery pg-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
          <Route exact path={`${match.path}`} component={GalleryOverview} />
          <Route path={`${match.path}/:photoId`} component={Viewer} />
        </div>
);

export default PhotoGallery;