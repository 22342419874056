import React from 'react';
import photoData from '../../components/menu-item/photo-data';
import MenuItem from '../../components/menu-item/menu-item.component';
import imageUrl from '../../assets/iceland_mtn-9518.jpg';
import './gallery-overview.styles.scss';

const GalleryOverview = ({match}) => (
    <div className='gallery-overview go-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
    <div className='gallery-title'><h2>{photoData.title}</h2></div>
    <div className='directory-menu '>      
          {photoData.photos.map(({id, ...otherPhotoDataProps }) => (
              <MenuItem key={id} {...otherPhotoDataProps} />
          )
          )}
      </div>
  </div>
);

export default GalleryOverview;