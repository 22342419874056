import React from 'react';
import { withRouter } from 'react-router-dom';
import './menu-item.styles.scss';

const MenuItem= ({ title, imageUrl, size ,history, linkUrl, gitHubUrl}) => (
    <div  className={`${size} menu-item`} onClick={() => history.push(`${linkUrl}`)}>
        <div className='background-image'
        style={{backgroundImage: `url(${imageUrl})`, opacity: 0.5}}
        />
        <div className='content'>
            <h1 className='title'>{title.toUpperCase()}</h1>
            {(gitHubUrl)
              ?  <a href={`${gitHubUrl}`}><i className="fab fa-github-square fa-2x social-icon"></i></a>
            : <span className='subtitle'>View</span> }
        </div>
    </div>  
);

export default withRouter(MenuItem);