import React from 'react';
import imageUrl from '../../assets/iceland_mtn-9518.jpg';


import './coach.styles.scss';

const Coach = () => (
    <div className='coach coach-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
    <div className='gallery-title'><h2>Professional & Career Coaching</h2></div>
        <div className='coach-container'>
            <span>Coming soon ... </span>
    </div>
    </div>
);

export default Coach;