const DevData = {
    title: 'Web Development',
    apps: [
        {
            title: 'PT Tracker',
            imageUrl: '/img/pt.png',
            linkUrl: 'webdev/pttracker',
            gitHubUrl: 'https://github.com/kcrites/trainingtracker',
            id: 1
        },
        {
            title: 'BBall Stats',
            imageUrl: '../../assets/wildlife/wildlife-2.jpg',
            linkUrl: 'webdev/bball',
            gitHubUrl: 'https://github.com/kcrites/bballtracker',
            id: 2
        },
        {
            title: 'Online Store',
            imageUrl: '/img/clothing.png',
            gitHubUrl: 'https://github.com/kcrites/clothing',
            linkUrl: 'webdev/clothes',
            id: 3
        },
    ]
};

export default DevData;