
const photoData = {
    title:'Photo Gallery',
    photos: [
        {
            title: 'Wildlife',
            imageUrl: '/img/wildlife-0066.jpg',
            linkUrl: '/viewer/wildlife',
            id: 1,
        },
        {
            title: 'Planes',
            imageUrl: '/img/planes.jpg',
            linkUrl: '/viewer/planes',
            id: 2,
        },
        {
            title: 'Travel',
            imageUrl: '/img/travel-7666.jpg',
            linkUrl: '/viewer/travel',
            id: 3,
        }
    ]
};

export default photoData;