import React from "react";
//import ReactModal from 'react-modal';
//import imageUrl from '../../assets/iceland_mtn-9518.jpg';
import { withRouter } from 'react-router-dom';

import './viewer.styles.scss';

let linkUrl = '/photos';

const Viewer = ({ location, history }) => {
    let temp = location.pathname.slice(8);
    const travelUrl = '/travel-gallery';
    const wildlifeUrl = '/wildlife-gallery';
    const planeUrl = '/planes-gallery';
    let currentSelection = '';
    switch(temp){
        case 'travel':
            currentSelection = travelUrl;
            break;
        case 'wildlife':
            currentSelection = wildlifeUrl;
            break;
        default:
            currentSelection =  planeUrl;
            break;
    }

  return (
      <div className='gallery-container'>
        <div className='back-link' onClick={() => history.push(`${linkUrl}`)}><i class="fas fa-caret-square-left"></i> Gallery List</div>
        <iframe src={`${currentSelection}`} className='frame-size' title='photogalleryframe'/>
   </div>
  );
}

export default withRouter(Viewer);

    /*  <div className='viewer viewer-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
     {!toggleViewer 
      ? <Gallery photos={photos} onClick={openLightbox} />
      : null
     }
     <ReactModal 
          isOpen={toggleViewer}
          contentLabel="Photo Gallery"
          className="Model"
          overlayClassName="Overlay"
          parentSelector={() => document.querySelector('#root')}>
         <span className='modal-close-icon' onClick={closeLightbox}>Close</span>
         <ImageGallery items={images} showNav={true} startIndex={currentImage} />
       </ReactModal>
      
   </div> */