import React from 'react';
import Social from '../../components/social/social.component';

import './homepage.styles.scss';

import imageUrl from '../../assets/iceland_mtn-9518.jpg';

const HomePage = () => (
    <section className="hero">
    <div className="background-image" style={{backgroundImage: `url(${imageUrl})`}}></div>
    <div className="name">
        <h1>Ken Crites</h1>
        <h3>kencrites.com</h3>
        <Social />
    </div>
</section>
);

export default HomePage;