import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './navigation.styles.scss';

const Navigation = ({history}) => {

    let homepage = false;
    const pathFlag = {
        photo: 'header-text',
        webdev: 'header-text',
        coaching: 'header-text',
        about: 'header-text'
    }
    if(history.location.pathname === '/'){
        homepage = true;
    };
   
   switch(history.location.pathname){
       case '/photos':
           case '/viewer/travel':
            case '/viewer/wildlife':
            case '/viewer/planes':
           pathFlag.photo = 'header-text-bold';
           break;
        case '/webdev':
            pathFlag.webdev = 'header-text-bold';
            break;
        case '/about':
            pathFlag.about = 'header-text-bold';
            break;
        case '/coaching':
            pathFlag.coach = 'header-text-bold';
            break;
        default:
            break;
   }
   
    return(
    <div className='header'>
        <header className='header-group'>
        {
                homepage ?
                null
                :
                <div className="header-sitename">KenCrites.com</div>
                
            }
            <div className="header-text"><Link className={`${pathFlag.photo}`} to='/photos'>Photography</Link></div>
            <div className="header-text"><Link className={`${pathFlag.webdev}`} to='/webdev'>Web Dev</Link></div>
            <div className="header-text"><Link className={`${pathFlag.coach}`} to='/coaching'>Coaching</Link></div>
            <div className="header-text"><Link className={`${pathFlag.about}`} to='/about'>About</Link></div>
            {
                homepage ?
                null
                :
                <div className="header-text"><Link className='option' to='/'>Home</Link></div>
                
            }
        </header>
    </div>
    );
};

export default withRouter(Navigation);