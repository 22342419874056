import React from 'react';

import './social.styles.scss';

const SocialIcons = () => (
    <div className='social'>
        <div><a href="https://www.facebook.com/ken.crites/"><i className="fab fa-facebook-square fa-2x social-icon"></i></a></div>
        <div><a href="https://github.com/kcrites"><i className="fab fa-github-square fa-2x social-icon"></i></a></div>
        <div><a href="https://www.linkedin.com/in/kencrites/"><i className="fab fa-linkedin fa-2x social-icon"></i></a></div>
        <div><a href="https://twitter.com/KenRCrites"><i className="fab fa-twitter-square fa-2x social-icon"></i></a></div>
        <div><a href="mailto:ken@kencrites.com"><i className="fas fa-envelope-square fa-2x social-icon end"></i></a></div>
    </div>
);

export default SocialIcons;