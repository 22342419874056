import React from 'react';
import imageUrl from '../../assets/iceland_mtn-9518.jpg';


import './about.styles.scss';

const About = () => (
    <div className='about about-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
    <div className='gallery-title'><h2>About Me</h2></div>
        <div className='about-container'>
            <span className='about-span'>Profesionally I am a customer success leader</span><br />
            <span className='about-span'> and program manager in the software industry. </span><br />
           
            <span className='about-span'>My hobbies include bird and travel photogrphy</span><br />
            <span className='about-span'>I am based in Amsterdam</span>
        </div>
    </div>
);

export default About;