import React from 'react';
import imageUrl from '../../assets/iceland_mtn-9518.jpg';
import DevData from '../../components/webdev/webdev-data';
import MenuItem from '../../components/menu-item/menu-item.component';

import './webdev.styles.scss';

const WebDev = () => (
    <div className='webdev wd-background-image' style={{backgroundImage: `url(${imageUrl})`}}>
    <div className='gallery-title'><h2>{DevData.title}</h2></div>
    <div className='directory-menu '>
          {DevData.apps.map(({id, ...otherPhotoDataProps }) => (
              <MenuItem key={id} {...otherPhotoDataProps} />
          )
          )}
      </div>
  </div>

);

export default WebDev;